import React, { useState, useEffect } from 'react'
import { PageTemplate } from '../templates/page'
import { list, total } from 'cart-localstorage'
import SiteMetadata from '../components/SiteMetadata'
import { loadStripe } from '@stripe/stripe-js'
import Button from '../components/shortcodes/Button'
import CheckoutPageStyles from '../styles/CheckoutPage.module.sass'

const pageProps = {
  title: "Checkout",
  subtitle: "Pay quickly and securely for your fresh sandwich platters!",
  pageIdentifier: "checkout",
  pageClass: "hide-cart",
  showCTA: false,
  footerDimmed: true,
  footerCTAText: "Need Help? Contact Us",
  footerCTALink: "/contact/",
}

function convertTo24h(time) {
  if (time === '12:00 PM') {
    return 12
  } else if (time === '12:00 AM') {
    return 0
  } else if (time.includes('AM')) {
    return parseInt(time.replace(':00 AM', ''), 10)
  } else if (time.includes('PM')) {
    return parseInt(time.replace(':00 PM', ''), 10) + 12
  } else {
    return false
  }
}

function convertToAmPm(time) {
  if (time === 0 || time === 24) {
    return '12:00 AM'
  } else if (time < 12) {
    return time + ':00 AM'
  } else if (time === 12) {
    return '12:00 PM'
  } else if (time < 24) {
    return time - 12 + ':00 PM'
  } else {
    return false
  }
}

function convertTimestampToHTML5Date(date) {
  var dd = date.getDate()
  var mm = date.getMonth()+1 //January is 0
  var yyyy = date.getFullYear()

  if(dd<10){
    dd='0'+dd
  } 
  if(mm<10){
    mm='0'+mm
  }

  return yyyy + '-' + mm + '-' + dd
}

const PaymentPage = () => {
  // Define states
  const [deliveryType, setDeliveryType] = useState('Delivery')
  const [deliveryCharge, setDeliveryCharge] = useState(12)
  const [selectedDeliveryCharge, setSelectedDeliveryCharge] = useState(12)
  const [settledMinDate, setSettledMinDate] = useState('')

  // Show error if cart is empty
  let cart = (typeof window !== 'undefined') ? list() : null
  let subtotal = (typeof window !== 'undefined') ? total() : null

  // YYYY-MM-DD
  const blockedDates = [
    // {
    //   date: '2024-12-24',
    //   readableDate: '24th Dec 2024',
    //   name: 'Tue'
    // }
  ]

  let blockedDatesMessage = ''
  let blockedDatesMessageFull = ''

  if (blockedDates && blockedDates[0]) {
    blockedDatesMessage += 'Please note that we are fully booked for '

    for (const item of blockedDates) {
      blockedDatesMessage += item.readableDate + ' (' + item.name + '), '
    }

    blockedDatesMessage = blockedDatesMessage.slice(0, -2)

    blockedDatesMessageFull = blockedDatesMessage + '. Please choose a different delivery date.'
  }

  const checkIfDateIsBlocked = (date) => {
    let selectedBlockedDate = false

    for (const item of blockedDates) {
      if (date === item.date) {
        selectedBlockedDate = true
        setSettledMinDate(minDate)
        alert(blockedDatesMessageFull)
        // window.location.reload()
      }
    }

    if (!selectedBlockedDate) {
      setSettledMinDate(date)
      // Check if it's a Saturday or Sunday
      let day = new Date(date).getUTCDay()

      // if ([6, 0].includes(day)) {
      if (['disabled'].includes(day)) {
        setSettledMinDate(minDate)
        alert('Our platters can only be ordered for Monday-Friday. Please choose a different date.')
      }
    }
  }

  if (cart && cart.length === 0) {
    return (
      <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        footerCTAText={pageProps.footerCTAText}
        footerCTALink={pageProps.footerCTALink}
        footerDimmed={pageProps.footerDimmed}
      >
        <div className="has-text-centered">
          <div className="notification has-text-left">
            You can't proceed with the <strong>Checkout</strong> because your cart is empty.
          </div>
          <Button url="/" text="Shop Now" />
        </div>
      </PageTemplate>
    )
  }

  const {
    collectionAddress,
    earliestCollectionOrDelivery,
    latestCollectionOrDelivery,
  } = SiteMetadata()

  // Create available dates logic
  // Min: Tomorrow (or the day after tomorrow if it's after 4pm)
  let minDate = new Date()
  let minDateTime = minDate.getHours()
  let minDateValid = false

  if (minDateTime >= 13) {
    // It's after 13:00 (1pm), min date is the date after tomorrow 
    minDate.setDate(minDate.getDate() + 1)
  }
  else {
    // It's before 1pm, min date is tomorrow
    minDate.setDate(minDate.getDate() + 0)
  }

  while (minDateValid === false) {
    let minDateTempTimestamp = new Date()
    minDateTempTimestamp.setDate(minDate.getDate() + 1)
    let minDateTemp = convertTimestampToHTML5Date(minDateTempTimestamp)

    if (blockedDates && blockedDates[0]) {
      let temporaryValidity = true

      for (const item of blockedDates) {
        if (minDateTemp === item.date) {
          temporaryValidity = false
        }
      }

      minDateValid = temporaryValidity
    }
    else {
      minDateValid = true
    }

    minDate.setDate(minDate.getDate() + 1)
  }

  minDate = convertTimestampToHTML5Date(minDate)

  // Max: in 1 year
  let maxDate = new Date()
  maxDate.setDate( maxDate.getDate() + 365 )
  maxDate = convertTimestampToHTML5Date(maxDate)

  // Create times dropdown
  let availableTimes = []
  let earliestCollectionOrDelivery24h = convertTo24h(
    earliestCollectionOrDelivery
  )
  let latestCollectionOrDelivery24h = convertTo24h(latestCollectionOrDelivery)

  let iterations =
    latestCollectionOrDelivery24h - earliestCollectionOrDelivery24h

  for (iterations; iterations >= 0; iterations--) {
    availableTimes.unshift(
      convertToAmPm(earliestCollectionOrDelivery24h + iterations)
    )
  }

  async function processForm(event) {
    event.preventDefault()

    let data = {}
    data['cart'] = cart
    data['total'] = subtotal

    let fields = event.target.querySelectorAll(
      '.field:not(.is-hidden) input, .field:not(.is-hidden) select'
    )

    for (let field of fields) {
      data[field.name] = field.value
    }

    const response = await fetch('/.netlify/functions/stripe-checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(res => res.json())

    const stripe = await loadStripe(response.publishableKey)

    const { error } = await stripe.redirectToCheckout({
      sessionId: response.sessionId,
    })

    if (error) {
      console.error(error)
    }
  }

  return (
    <PageTemplate 
        title={pageProps.title}
        subtitle={pageProps.subtitle}
        pageIdentifier={pageProps.pageIdentifier}
        pageClass={pageProps.pageClass}
        showCTA={pageProps.showCTA}
        footerCTAText={pageProps.footerCTAText}
        footerCTALink={pageProps.footerCTALink}
        footerDimmed={pageProps.footerDimmed}
    >
      <form
        className={
          'columns is-multiline pay-form ' + (CheckoutPageStyles.form || '')
        }
        onSubmit={event => processForm(event)}
      >
        {
          blockedDates && blockedDates[0] && <div className="notification is-warning has-text-centered">
            {blockedDatesMessage}
          </div>
        }
        <div className="field column is-half">
          <label htmlFor="name" className="label">
            Full Name
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="name"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="phone" className="label">
            Phone No.
          </label>
          <div className="control">
            <input
              className="input"
              type="tel"
              name="phone"
              placeholder=""
              pattern="[0-9 +]*"
              required
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="company" className="label">
            Company <small className="tag is-primary has-text-white">optional</small>
          </label>
          <div className="control">
            <input className="input" type="text" name="company" placeholder="" />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="email" className="label">
            E-mail address
          </label>
          <div className="control">
            <input
              className="input"
              type="email"
              name="email"
              placeholder=""
              required
            />
          </div>
        </div>
        <div className="field column is-half" style={{ display: "none" }}>
          <label htmlFor="delivery_type" className="label">
            Collection / Delivery Options
          </label>
          <div className="control">
            <div className="select" required>
              <select // eslint-disable-line jsx-a11y/no-onchange
                defaultValue={deliveryType}
                onChange={event => { 
                    setDeliveryType(event.target.value)

                    // if ( event.target.value === 'Collection' ) {
                    //   setDeliveryCharge(0)
                    // }
                    // else {
                    //   setDeliveryCharge(selectedDeliveryCharge)
                    // }

                    setDeliveryCharge(selectedDeliveryCharge)
                  } 
                }
                name="delivery_type"
              >
                {/* {<option>Collection</option>} */}
                <option>Delivery</option>
              </select>
            </div>
          </div>
        </div>
        <div className="column is-full">
          <hr />
        </div>
        <div className="field column is-half">
          <label htmlFor="date" className="label">
            {deliveryType} Date
          </label>
          <div className="control">
            <input
              className="input"
              name="date"
              type="date"
              placeholder="DD/MM/YYYY"
              pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)"
              min={minDate}
              max={maxDate}
              value={settledMinDate || minDate}
              required
              onChange={event => {
                  checkIfDateIsBlocked(event.target.value)
                }
              }
            />
          </div>
        </div>
        <div className="field column is-half">
          <label htmlFor="time" className="label">
            {deliveryType} Time
          </label>
          <div className="control">
            <div className="select">
              <select name="time" required>
                {availableTimes.map(time => {
                  return <option key={time}>{time}</option>
                })}
              </select>
            </div>
          </div>
        </div>
        {/*
          deliveryType === 'Collection'
          && <div className={'field column is-full' + CheckoutPageStyles.collectionAddress}><strong>Collection Address:</strong> {collectionAddress}</div>
        */}
        <div
          className={
            'field column is-half ' +
            (deliveryType === 'Collection' && 'is-hidden')
          }
        >
          <label htmlFor="address" className="label">
            Delivery Address
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="address"
              placeholder=""
              required={deliveryType === 'Delivery' ? true : false}
            />
          </div>
        </div>
        <div
          className={
            'field column is-half ' +
            (deliveryType === 'Collection' && 'is-hidden')
          }
        >
          <label htmlFor="city" className="label">
            City or Town
          </label>
          <div className="control">
            <input
              className="input"
              type="text"
              name="city"
              placeholder=""
              required={deliveryType === 'Delivery' ? true : false}
            />
          </div>
        </div>
        <div
          className={
            'field column is-half ' +
            (deliveryType === 'Collection' && 'is-hidden')
          }
        >
          <label htmlFor="county" className="label">
            County
          </label>
          <div className="control">
            <div className="select">
              <select // eslint-disable-line jsx-a11y/no-onchange
                name="county"
                // required={deliveryType === 'Delivery' ? true : false}
                required={true}
                defaultValue={"Dublin Southside"}
                onChange={(event) => { 
                    let selectedOption = event.target.querySelectorAll('option[value="' + event.target.value + '"]')
                    let deliveryCharge = selectedOption[0].getAttribute('data-price')

                    setSelectedDeliveryCharge( deliveryCharge )
                    
                    setDeliveryCharge( deliveryCharge )
                  }
                }
              >
                <option data-price="12" value="Dublin Southside">Dublin Southside (&euro;12)</option>
                <option data-price="18" value="Dublin Northside">Dublin Northside (&euro;18)</option>
                <option data-price="30" value="Wicklow">Wicklow (&euro;30)</option>
                <option data-price="30" value="Kildare">Kildare (&euro;30)</option>
              </select>
            </div>
          </div>
        </div>
        <div
          className={
            'field column is-half ' +
            (deliveryType === 'Collection' && 'is-hidden')
          }
        >
          <label htmlFor="eircode" className="label">
            Eircode
          </label>
          <div className="control">
            <input className="input" type="text" name="eircode" placeholder="" />
          </div>
        </div>
        <div className="column is-three-fifths is-offset-two-fifths">
          <div className={`notification is-warning is-light ${CheckoutPageStyles.totals || ''}`}>
            {
              (deliveryType === 'Delivery') && (
                <>
                  <p>Subtotal: <b>&euro;{subtotal}</b></p>
                  <p>Delivery: <b>&euro;{deliveryCharge}</b></p>
                </>
              )
            }
            <p><strong>Total: <b>&euro;{~~subtotal + ~~deliveryCharge}</b></strong></p>

            <button
            // disabled={loading}
            // onClick={redirectToCheckout}
            // className={`button ${ loading ? "is-light" : "is-link" }`}
              className={`button is-primary column is-full ${CheckoutPageStyles.submit || ''}`}
            >
              Pay Now
            </button>
          </div>
        </div>
      </form>
    </PageTemplate>
  )
}

export default PaymentPage
